export enum DataHook {
  WidgetWrapper = 'MyAccount-wrapper',
  SectionTitle = 'MyAccount-sectionTitle',
  EditProfileUrlModal = 'MyAccount-changeProfileUrlModal',
  DiscardChangesButton = 'MyAccount-discardChangesButton',
  DiscardChangesModal = 'MyAccount-discardChangesModal',
  ProfileUrlAccordionHeader = 'MyAccount-profileUrlAccordionHeader',
  VisibilityAccordionHeader = 'MyAccount-visibilityAccordionHeader',
  BlockedMembersAccordionHeader = 'MyAccount-memberBlockMemberAccordionHeader',
  BlockedMembersSeeAllButton = 'MyAccount-memberBlockMemberSeeAllButton',
  BlockedMembersModal = 'MyAccount-memberBlockMemberModal',
  BlockedMemberModal = 'tpa-modal-content',
  EditProfileUrlButton = 'MyAccount-editProfileUrlButton',
  ProfilePrivacyModal = 'MyAccount-profilePrivacyModal',
  ChangeProfilePrivacyButton = 'MyAccount-changeProfilePrivacyButton',
  FieldPrivacyModal = 'MyAccount-fieldPrivacyModal',
  FieldPrivacyIcon = 'MyAccount-fieldPrivacyIcon',
  TextField = 'MyAccount-textField',
  EditProfileUrlSlugInput = 'MyAccount-editProfileUrlSlugInput',
  AddressFieldPrivacyIcon = 'MyAccount-addressFieldPrivacyIcon',
  MainTab = 'MyAccountSettings-mainTab',
  MainTabContainer = 'MyAccountSettings-mainTab-container',
  DesignTab = 'MyAccountSettings-designTab',
  LayoutTab = 'MyAccountSettings-layoutTab',
  SupportTab = 'MyAccountSettings-supportTab',
  TextTab = 'MyAccountSettings-textTab',
  SaveAccountButton = 'MyAccount-saveAccountButton',
  Toast = 'MyAccount-toast',
  SettingsTab = 'MyAccountSettings-settingsTab',
  MyAccountSettings = 'MyAccountSettings',
  BlockedMemberSettings = 'BlockedMemberSettings',
  MyAccountDropdownFieldText = 'dropdown-base-text',
  Avatar = 'MyAccount-avatar',
  DisplayInfoSection = 'MyAccount-displayInfoSection',
}
