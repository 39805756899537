import React, { FC } from 'react';
import { useField as useFormikField } from 'formik';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/yoshi-flow-editor/tpa-settings/react';

import { classes, st } from './FieldPrivacyIcon.st.css';
import {
  Field,
  FieldPrivacy,
  PageAlignment,
  ProfilePrivacyStatus,
} from '../../../../../types';
import { PrivacyIcon } from '../PrivacyIcon';
import { useModal } from '../../../../../contexts/modals/hooks';
import { FieldPrivacyModal } from '../../modals';
import { useMember, useSiteContext } from '../../../../../contexts/widget';
import settingsParams from '../../../settingsParams';

type Props = {
  formikFieldSelector: string;
  'data-hook'?: string;
};

export const FieldPrivacyIcon: FC<Props> = ({
  formikFieldSelector,
  children,
  'data-hook': dataHook,
}) => {
  const { isMobile } = useEnvironment();
  const { showModal } = useModal();
  const { privacyStatus } = useMember();
  const { isSocial } = useSiteContext();
  const [formikField, , helpers] = useFormikField<Field>(formikFieldSelector);
  const field = formikField.value;
  const { privacy, isPrivacyEditable, index } = field;
  const settings = useSettings();

  const onPrivacyChange = (newPrivacy: FieldPrivacy) => {
    const updatedField = { ...field, privacy: newPrivacy };
    helpers.setValue(updatedField);
  };
  const openPrivacyModal = () =>
    showModal(FieldPrivacyModal, { field, onChange: onPrivacyChange });

  const isPublicProfile = privacyStatus === ProfilePrivacyStatus.PUBLIC;
  const shouldShowPrivacyIcon =
    isSocial && isPublicProfile && isPrivacyEditable && !index;

  const pageAlignment = settings.get(
    settingsParams.pageAlignment,
  ) as PageAlignment;

  return (
    <div
      className={st(classes.root, {
        mobile: isMobile,
        pageAlignedToRight: pageAlignment === PageAlignment.right,
      })}
    >
      <div className={classes.fieldContainer}>{children}</div>
      <div className={classes.icon}>
        {shouldShowPrivacyIcon && (
          <PrivacyIcon
            privacy={privacy}
            onClick={openPrivacyModal}
            data-hook={dataHook}
          />
        )}
      </div>
    </div>
  );
};
