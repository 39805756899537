import React, { FC } from 'react';

export const CaretUp: FC = () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill="#000"
      d="M12 15L6 9.76 6.657 9l5.342 4.666L17.343 9l.657.76z"
      fillRule="evenodd"
      transform-origin="center"
      transform="rotate(180)"
    />
  </svg>
);
