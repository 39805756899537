import { useTranslation } from '@wix/yoshi-flow-editor';
import React, { FC } from 'react';
import { Text } from 'wix-ui-tpa/Text';
import { TextButton } from 'wix-ui-tpa/TextButton';
import { DataHook } from '../../../../../../../constants/DataHook';
import { useModal } from '../../../../../../../contexts/modals/hooks';
import { useMember } from '../../../../../../../contexts/widget';
import { useBi } from '../../../../../../../hooks';
import { BiActionType, MY_ACCOUNT_REFERRAL } from '../../../../../../../types';
import { Accordion } from '../../../../common/Accordion';
import { BlockedMembersModal } from '../../../../modals/BlockedMembersModal/BlockedMembersModal';

import { classes } from './BlockedMembers.st.css';

export const BlockedMembers: FC = () => {
  const { t } = useTranslation();
  const { showModal } = useModal();
  const { blockedMembers } = useMember();
  const biLogger = useBi();

  const onCtaClick = () => {
    biLogger.ctaButtonClicked(BiActionType.Unblock_Selected_Members);
    showModal(BlockedMembersModal);
    biLogger.blockedMembersListOpen(blockedMembers.length);
  };

  return (
    <Accordion.Item
      data-hook={DataHook.BlockedMemberSettings}
      header={
        <Text
          className={classes.header}
          data-hook={DataHook.BlockedMembersAccordionHeader}
        >
          {t('app.widget.blocked-members.title')}
        </Text>
      }
      onClick={() =>
        biLogger.ctaButtonClickedWithProps(
          BiActionType.Blocked_Members_Drawer,
          { formOrigin: MY_ACCOUNT_REFERRAL },
        )
      }
    >
      {!!blockedMembers?.length && (
        <>
          <Text className={classes.content}>
            {t('app.widget.blocked-members.subtitle')}
          </Text>{' '}
          <TextButton
            onClick={onCtaClick}
            className={classes.content}
            data-hook={DataHook.BlockedMembersSeeAllButton}
          >
            {t('app.widget.blocked-members.unblock-list-cta')}
          </TextButton>
        </>
      )}
      {!blockedMembers?.length && (
        <Text className={classes.content}>
          {t('app.widget.blocked-members.empty-state')}
        </Text>
      )}
    </Accordion.Item>
  );
};
